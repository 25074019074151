.square-btn,
.rect-btn,
.circle-btn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  border: solid 1px gray;
  border-radius: 5px;
  box-shadow: inset -2px -2px 10px #424242;
  background-color: #363636;

  .icon, p {
    font-size: 15px;
    color: whitesmoke;
  }
  p {margin: 0 10px;}

  &:hover {
    opacity: 1;
    transition: 0.3s opacity;
  }
}
.square-btn {aspect-ratio: 1;}
.circle-btn {
  aspect-ratio: 1;
  border-radius: 50%;
}

.text-and-icon-btn {
  padding: 0 10px;
  justify-content: space-between;

  p {margin: 0 10px 0 0;}
  .icon {font-size: 12px;}
}