@import "../scss_components/polygons";

/* Track styles */
.react-custom-scrollbars-track {
  background-color: #f5f5f5;
}

.vertical-scrollbar {
  background-color: whitesmoke;
  opacity: 0.5;
}