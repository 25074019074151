@import "../scss_components/buttons";
@import "../scss_components/animations";

.hero-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .intro-desc {
    font-size: 25px;
    color: whitesmoke;
  }

  .intro-name{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
      position: absolute;
      font-family: "Poppins", sans-serif;
      font-size: 125px;

      &:nth-child(1) {
        color: transparent;
        -webkit-text-stroke: 1px #a77bf3;
      }

      &:nth-child(2) {
        animation: wave 4s ease-in-out infinite;
        background-image: linear-gradient(#a77bf3, #424242);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .circle-btn {
    margin: 35px 0 0 0;
    height: 35px;

    .icon {
      font-size: 20px;
      color: white;
    }
  }
}

/* Mobile devices */
@media screen and (max-width: 600px) {
  .hero-section {
    .intro-desc {font-size: max(3dvw, 10px);}
    .intro-name{
      height: 75px;
      h1 {font-size: max(11dvw, 35px);}
    }
    .circle-btn {
      margin: 20px 0 0 0;
      height: 30px;
      .icon {font-size: 16px;}
    }
  }
}

/* iPads, Tablets */
@media screen and (min-width: 601px) and (max-width: 768px) {
  .hero-section {
    .intro-desc {font-size: 17px;}
    .intro-name{
      height: 125px;
      h1 {font-size: 65px;}
    }
    .circle-btn {
      margin: 20px 0 0 0;
      height: 30px;
      .icon {font-size: 16px;}
    }
  }
}

/* Small screens, laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .hero-section {
    .intro-desc {font-size: 20px;}
    .intro-name{
      height: 175px;
      h1 {font-size: 80px;}
    }
    .circle-btn {
      margin: 30px 0 0 0;
      height: 30px;
      .icon {font-size: 17px;}
    }
  }
}

/* Large Devices (Desktops) */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .hero-section {
    .intro-desc {font-size: 24px;}
    .intro-name{
      height: 175px;
      h1 {font-size: 110px;}
    }
  }
}