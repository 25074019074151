@import "../scss_components/buttons";
@import "../scss_components/animations";

.profile {
  max-width: 400px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: solid 2px gray;
  box-shadow: inset -2px -2px 10px #424242;
  background-color: rgba(54, 54, 54, 0.75);
  animation: fade-in 0.25s ease-in-out forwards;
  overflow: hidden;
  backdrop-filter: blur(10px);

  .profile-redirects {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    pointer-events: auto;

    .profile-links {
      height: 100%;
      flex: 1;
      max-width: 150px;
      display: flex;
      justify-content: center;
      gap: 30px;
    }

    .resume-btn {
      opacity: 1;
      border-color: #a77bf3;
      background-color: transparent;
      box-shadow: inset -2px -2px 10px #320b75;
      p, .icon {color: #a77bf3;}
    }
  }

  .profile-carousel {
    width: 100%;
  }

  .line {
    width: 100%;
    margin: 20px 0;
    border-top: solid 1px gray;
  }

  .about-text {
    width: 100%;
    font-size: 14px;
    color: whitesmoke;
    text-align: justify;
  }
}

/* Mobile devices */
@media screen and (max-width: 600px) {
  .profile .profile-redirects .profile-links {
    justify-content: space-between;
    gap: unset;
  }
}