@import url('https://fonts.googleapis.com/css2?family=Changa&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

p {font-family: 'Changa', sans-serif;}

.fixed {
  position: fixed;
  top: 0;
  min-width: 320px;
  padding: 0 75px;
}

.app {
  position: unset;
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to bottom right, #000414, #19001f, #000f0e);

  main {
    width: 100%;
    max-width: 1500px;

    .hero-section-container {
      min-height: 575px;
      height: 100dvh;
      width: 100%;
    }

    .projects-section-container {
      min-height: 500px; // temp
    }
    .experience-section-container {
      min-height: 500px; // temp
    }
    .skills-section-container {
      min-height: 500px; // temp
    }
    .achievements-section-container {
      min-height: 500px; // temp
    }
    .contact-section-container {
      min-height: 500px; // temp
    }
  }

  .nav-bar-container {
    z-index: 999;
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
  }

  .profile-container {
    z-index: 999;
    top: 60px;
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
  }
}


/* Mobile devices */
@media screen and (max-width: 600px) {
  .fixed { padding: 0 20px; }
  .app main .hero-section-container {
    min-height: unset;
    height: 350px;
    padding: 60px 0 0 0;
  }
}

/* iPads, Tablets */
@media screen and (min-width: 601px) and (max-width: 768px) {
  .fixed { padding: 0 30px; }
  .app main .hero-section-container {
    min-height: unset;
    height: 400px;
    padding: 90px 0 0 0;
  }
}

/* Small screens, laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .fixed { padding: 0 50px; }
}

/* Large Devices (Desktops) */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .fixed { padding: 0 50px; }
}